(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name ProjectController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('ProjectController', ProjectController);
    ProjectController.$inject = ["ProjectService","$timeout"];

    function ProjectController(ProjectService,$timeout){
  
        var vm = this;
        vm.projects =[];
        vm.servicios =[];
        vm.filtros = {};
        vm.filtros.page = 0;
        vm.error = false;
        vm.limit = 18;
        vm.showmore=false;
        vm.loading=false;
        vm.initialLoading = true;
        
        vm.getProjects = function(noreset){      
        	if (!noreset) {
                vm.projects =[];
                vm.filtros.page = 0;
            }
            ProjectService.ProjectsFiltros(vm).then(function(data){ 
                vm.initialLoading = true;
                console.log(vm.servicio_id);
                vm.projects = vm.projects.concat(data.projects);
                vm.loading =true;
                vm.maxPages =(data.maxPages)-1;          
	                if ( vm.projects.length === 0) {
	                    vm.error = true;
	                }
            }).finally(function() {
                vm.initialLoading = false; 
            });
                vm.error = false;
                vm.loading = false;
        }

        vm.removeServicio_id = function(servicio_id){
  			delete vm.filtros.cat_service_id[servicio_id];
        } 

        vm.getServicios = function(){
            ProjectService.Servicio_id().then(function(data){ 
                vm.servicios = data.servicios;
                
            });
        } 


    }    

    })();