(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name BlogsController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('BlogsController', BlogsController);
    BlogsController.$inject = ["BlogsService","ProjectService"];

    function BlogsController(BlogsService,ProjectService){
    
        var vm = this;
        vm.blogs =[];
        vm.servicios =[];
        vm.filtros = {};
        vm.filtros.page = 0;
        vm.error = false;
        vm.showmore = false;
        vm.loading = false;
        vm.initialLoading = true;
        var bannerId = document.getElementById('banner_id').value;
        vm.bannerId = bannerId;

        vm.getServicios = function(){
            ProjectService.Servicio_id().then(function(data){ 
                vm.servicios = data.servicios;
                
            });
        } 
        
        vm.getBlogs = function(noreset){ 

            if (!noreset) {
                vm.blogs =[];
                vm.filtros.page = 0;
                vm.initialLoading = true;

            }
            BlogsService.BlogsFiltros(vm).then(function(data){ 
                vm.limit = data.limit;
                vm.blogs = vm.blogs.concat(data.blogs);
                vm.loading =true;
                vm.maxPages =(data.maxPages)-1;          
                    if ( vm.blogs.length === 0) {
                        vm.error = true;
                    }
            }).finally(function() {
                vm.initialLoading = false; 
            });
                vm.error = false;
                vm.loading = false;


        }

      

        vm.applyFilters = function() {
            vm.filtros.page = 0;
            vm.getBlogs();
        };
        
        vm.removeServicio_id = function(servicio_id){
            delete vm.filtros.cat_service_id[servicio_id];
        } 

   

    }    

    })();