(function(){
     'use strict';

     angular
        .module('app')
        .factory('BlogsService', BlogsService);

    BlogsService.$inject = ['$http'];

    function BlogsService($http){
        
        var service= {
            BlogsFiltros:BlogsFiltros,
        };

        function BlogsFiltros(vm)
        {
            
            var config = {headers : {}}
              
            return $http.get('/ajax/blogsbyfiltros', { params : { filtros : JSON.stringify(vm.filtros), 
                cat_blogs:vm.cat_blogs,
                banner_id: vm.bannerId,
                lang_id:lang_id
            }   
            }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
        };

       	
        return service;
    }

     
})();