(function() {
    'use strict';

    angular.module('app',['ngSanitize']).config(function($interpolateProvider){
    //$interpolateProvider.startSymbol('{[{').endSymbol('}]}');
	}).config(function($httpProvider) { 
   		// $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
    	$httpProvider.defaults.headers.post['X-CSRF-Token'] = jQuery('meta[name="csrf-token"]').attr("content");
 	    $httpProvider.defaults.timeout = 30000;
	}).config(["$interpolateProvider",function($interpolateProvider){
        $interpolateProvider.startSymbol("[[");
        $interpolateProvider.endSymbol("]]");
    }]);
})();
 //'ngAnimate' 'ngSanitize'