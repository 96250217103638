(function(){
     'use strict';

     angular
        .module('app')
        .factory('ProjectService', ProjectService);

    ProjectService.$inject = ['$http'];

    function ProjectService($http){
        
        var service= {
            ProjectsFiltros:ProjectsFiltros,
            Servicio_id:Servicio_id,
        };

        function ProjectsFiltros(vm)
        {
            
            var config = {headers : {}}
                // offset:vm.offset, page:vm.page, limit:vm.limit,
            return $http.get('/ajax/projectsbyfiltros', { params : { filtros : JSON.stringify(vm.filtros), cat_service_id:vm.cat_service_id,servicio_id:vm.servicio_id,lang_id:lang_id
            }   
            }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
        };

    

        function Servicio_id(vm)
        {
            var config = {headers : {}}

            return $http.get('/ajax/services', {   
            }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
        };

        return service;
    }

     
})();